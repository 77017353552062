import { reactive } from "vue";
import { defineStore } from "pinia";
import { useBookingStore } from "@/stores/booking/booking";
import { useRouter, useRoute } from "vue-router";

interface Step {
    label: string;
    url: string;
    completed: boolean;
}

interface ExperienceSteps {
    [experience: string]: Step[];
}

interface BookingStepsState {
    steps: ExperienceSteps;
    currentStepIndex: number;
}

const initialState = (): BookingStepsState => {
    return {
        steps: {
            hotel_stay: [
                { label: "Booking Details", url: "/", completed: false },
                {
                    label: "Your Stay",
                    url: "/experience/hotel-stay",
                    completed: false,
                },
                {
                    label: "Extras",
                    url: "/experience/hotel-stay/extras",
                    completed: false,
                },
                {
                    label: "Checkout",
                    url: "/experience/checkout",
                    completed: false,
                },
                {
                    label: "Checkout",
                    url: "/experience/checkout",
                    completed: false,
                },
            ],
            stay: [
                { label: "Experience Details", url: "/", completed: false },
                {
                    label: "Your Experience",
                    url: "/experience/stays",
                    completed: false,
                },
                {
                    label: "Treatments",
                    url: "/experience/stays/treatments/inclusives",
                    completed: false,
                },
                {
                    label: "Treatments",
                    url: "/experience/stays/treatments/additional",
                    completed: false,
                },
                {
                    label: "Extras",
                    url: "/experience/stays/extras",
                    completed: false,
                },
                {
                    label: "Checkout",
                    url: "/experience/checkout",
                    completed: false,
                },
            ],
            spa_day: [
                { label: "Experience Details", url: "/", completed: false },
                {
                    label: "Your Experience",
                    url: "/experience/spa-day/",
                    completed: false,
                },
                {
                    label: "Treatments",
                    url: "/experience/spa-day/treatments/inclusives",
                    completed: false,
                },
                {
                    label: "Treatments",
                    url: "/experience/spa-day/treatments/additional",
                    completed: false,
                },
                {
                    label: "Extras",
                    url: "/experience/spa-day/extras",
                    completed: false,
                },
                {
                    label: "Checkout",
                    url: "/experience/checkout",
                    completed: false,
                },
            ],
            spa_evening: [
                { label: "Experience Details", url: "/", completed: false },
                {
                    label: "Your Experience",
                    url: "/experience/spa-evening/",
                    completed: false,
                },
                {
                    label: "Treatments",
                    url: "/experience/spa-evening/treatments/inclusives",
                    completed: false,
                },
                {
                    label: "Treatments",
                    url: "/experience/spa-evening/treatments/additional",
                    completed: false,
                },
                {
                    label: "Extras",
                    url: "/experience/spa-evening/extras",
                    completed: false,
                },
                {
                    label: "Checkout",
                    url: "/experience/checkout",
                    completed: false,
                },
            ],
            treatments: [
                { label: "Experience Details", url: "/", completed: false },
                {
                    label: "Your Experience",
                    url: "/experience/treatments",
                    completed: false,
                },
                {
                    label: "Checkout",
                    url: "/experience/checkout",
                    completed: false,
                },
            ],
            dining: [
                { label: "Booking Details", url: "/", completed: false },
                {
                    label: "Your Experience",
                    url: "/experience/afternoon-tea",
                    completed: false,
                },
                {
                    label: "Checkout",
                    url: "/experience/checkout",
                    completed: false,
                },
            ],
            gift_cards: [
                { label: "Experience Details", url: "/", completed: false },
                {
                    label: "Gift Cards",
                    url: "/experience/gift-cards",
                    completed: false,
                },
                {
                    label: "Gift Card Details",
                    url: `/experience/gift-cards/{slug}`,
                    completed: false,
                },
                {
                    label: "Checkout",
                    url: "/experience/checkout",
                    completed: false,
                },
            ],
        },
        currentStepIndex: 0,
    };
};

export const useBookingStepsStore = defineStore(
    "bookingSteps",
    () => {
        const router = useRouter();
        const route = useRoute();
        const state = reactive<BookingStepsState>(initialState());
        const bookingStore = useBookingStore();

        const getExperienceSteps = (): Step[] => {
            const experience = bookingStore.state.booking.experience;
            if (!experience || !state.steps[experience]) {
                console.warn("Experience not found or invalid");
                return [];
            }
            return state.steps[experience];
        };

        const initializeCurrentStep = () => {
            const experienceSteps = getExperienceSteps();
            const currentPath = route.path;

            const currentIndex = experienceSteps.findIndex(
                (step) =>
                    step.url === currentPath ||
                    (step.url.includes("{slug}") &&
                        matchDynamicSlug(step.url, currentPath))
            );

            if (currentIndex !== -1) {
                state.currentStepIndex = currentIndex;
            }
        };

        const matchDynamicSlug = (
            stepUrl: string,
            currentPath: string
        ): boolean => {
            const basePath = stepUrl.split("/{slug}")[0];
            return currentPath.startsWith(basePath);
        };

        const setDynamicGiftCardSlug = (slug: string): void => {
            state.steps.gift_cards[2].url = `/experience/gift-cards/${slug}`;
        };

        const goToStart = (): void => {
            state.currentStepIndex = 0;
            const currentStep = getCurrentStep();
            if (currentStep) {
                router.push(currentStep.url);
            }
        };

        const goToStep = (step: number): void => {
            state.currentStepIndex = step;
            const currentStep = getCurrentStep();
            if (currentStep) {
                router.push(currentStep.url);
            }
        };

        const getCurrentStep = (): Step | undefined => {
            const experienceSteps = getExperienceSteps();
            return experienceSteps[state.currentStepIndex];
        };

        const currentStepIsCompleted = (): boolean => {
            const currentStep = getCurrentStep();
            return currentStep ? currentStep.completed : false;
        };

        const goToNextStep = (): void => {
            const experienceSteps = getExperienceSteps();
            if (state.currentStepIndex < experienceSteps.length - 1) {
                state.currentStepIndex++;
                router.push(getCurrentStep()?.url || "/");
            }
        };

        const goToPreviousStep = (): void => {
            if (state.currentStepIndex > 0) {
                state.currentStepIndex--;
                router.push(getCurrentStep()?.url || "/");
            }
        };

        const goToNextUncompletedStep = (): void => {
            const experienceSteps = getExperienceSteps();
            for (let i = 0; i < experienceSteps.length; i++) {
                if (!experienceSteps[i].completed) {
                    state.currentStepIndex = i;
                    router.push(getCurrentStep()?.url || "/");
                    return;
                }
            }
        };

        const completeCurrentStep = (): void => {
            const currentStep = getCurrentStep();
            if (currentStep) {
                currentStep.completed = true;
            }
        };

        const resetJourney = (): void => {
            const experienceSteps = getExperienceSteps();
            experienceSteps.forEach((step) => (step.completed = false));
            state.currentStepIndex = 0;
        };

        const resetToStep = (stepIndex: number): void => {
            const steps = getExperienceSteps();
            if (stepIndex < 0 || stepIndex >= steps.length) {
                console.warn("Invalid step index");
                return;
            }
            state.currentStepIndex = stepIndex;
            for (let i = stepIndex + 1; i < steps.length; i++) {
                steps[i].completed = false;
            }
            router.push(getCurrentStep()?.url || "/");
        };

        const currentSteps = (): Step[] => {
            return getExperienceSteps();
        };

        const completeFirstStepAndGoToSecond = (): void => {
            const experienceSteps = getExperienceSteps();
            if (experienceSteps.length > 1) {
                experienceSteps[0].completed = true;
                goToStep(1);
            } else {
                console.warn("Not enough steps to navigate to the second step");
            }
        };

        const $reset = () => {
            Object.assign(state, initialState());
        };

        initializeCurrentStep();

        return {
            state,
            goToStart,
            goToStep,
            getCurrentStep,
            goToNextStep,
            goToPreviousStep,
            goToNextUncompletedStep,
            completeFirstStepAndGoToSecond,
            completeCurrentStep,
            currentSteps,
            currentStepIsCompleted,
            initializeCurrentStep,
            resetJourney,
            resetToStep,
            setDynamicGiftCardSlug,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
