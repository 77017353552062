import { reactive } from "vue";
import { defineStore } from "pinia";
import api from "@/api/axios";
import { useBookingStore } from "@/stores/booking/booking";
import { useSystemStore } from "@/stores/system/system";
import type {
    Rate,
    Room,
    BookingSpecialServiceItem,
    SpecialService,
    Error,
} from "@/types/index";
import { emitEvent } from "@/helpers/eventEmitter";
import { useResetStore } from "@/helpers/useResetStore";
import { useRouter } from "vue-router";
import * as Sentry from "@sentry/vue";

interface PmsState {
    isLoading: boolean;
    specialServices: SpecialService[];
    error: Error;
}

const initialState = (): PmsState => ({
    isLoading: false,
    specialServices: [],
    error: {
        hasError: false,
        message: "",
    },
});

export const usePmsStore = defineStore(
    "pms",
    () => {
        const state = reactive<PmsState>(initialState());
        const bookingStore = useBookingStore();
        const systemStore = useSystemStore();
        const resetStore = useResetStore();
        const router = useRouter();

        async function createPmsBooking(
            rate: Rate,
            room: Room,
            adults: number,
            booking_pms_item_id: number
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const venueId = bookingStore.getVenueId();
                const response = await api.post("api/pms/booking/create", {
                    booking_id: bookingStore.state.booking.id,
                    booking_pms_item_id: booking_pms_item_id,
                    start_date: bookingStore.state.booking.start_date,
                    end_date: bookingStore.state.booking.end_date,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    customer_id: systemStore.state.customerId,
                    rate_code: rate.rate_code,
                    room_type: room.room_type,
                    adults: adults,
                    venue_id: venueId,
                });

                bookingStore.state.booking = response.data.data;

                if (bookingStore.state.booking.experience === "stay") {
                    push.success({
                        title: "Booking Update",
                        message: `${rate.title} with a ${room.title} has been added to the cart.`,
                    });
                } else {
                    push.success({
                        title: "Booking Update",
                        message: `${rate.title} has been added to the cart.`,
                    });
                }

                if (response.data.analytics) {
                    emitEvent("analytics-event", {
                        type: "add_to_cart",
                        venue:
                            bookingStore.state.booking.venue_id === 100
                                ? "Hoar Cross Hall"
                                : "Eden Hall",
                        ecommerce: {
                            currency: "GBP",
                            add: {
                                products: [response.data.analytics.product],
                            },
                        },
                    });
                }

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(errorResponse, "Error creating PMS booking");

                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();

                    await systemStore.systemStatus();
                    await systemStore.createSession();

                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });

                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function removePmsBooking(
            rate: Rate | null,
            booking_pms_item_id: number,
            pms_folio_id: number
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/pms/booking/cancel", {
                    booking_id: bookingStore.state.booking.id,
                    booking_pms_item_id: booking_pms_item_id,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    pms_folio_id: pms_folio_id,
                });

                if (!response || !response.data) {
                    throw new Error(
                        "Unexpected response format: No data returned."
                    );
                }

                if (!response.data.data) {
                    throw new Error(
                        "Unexpected response format: Booking data missing."
                    );
                }

                bookingStore.state.booking = response.data.data;

                const rateTitle = rate ? rate.title : "";

                if (bookingStore.state.booking.experience === "stay") {
                    push.success({
                        title: "Booking Update",
                        message: `Your ${rateTitle} experience has been removed from the cart.`,
                    });
                } else {
                    push.success({
                        title: "Booking Update",
                        message: `Your ${rateTitle} experience has been removed from the cart.`,
                    });
                }

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(errorResponse, "Error removing pms booking");

                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function fetchSpecialServices(
            pms_folio_id: number
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const venueId = bookingStore.getVenueId();
                const response = await api.post("api/pms/services/list", {
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    pms_folio_id: pms_folio_id,
                    start_date: bookingStore.state.booking.start_date,
                    end_date: bookingStore.state.booking.end_date,
                    venue_id: venueId,
                });

                state.specialServices = response.data.data;

                console.log("specialServices", state.specialServices);

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "Error fetching special services"
                );

                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function addPmsSpecialService(
            serviceName: string,
            pmsFolioId?: number,
            bookingPmsItemId?: number,
            bookingPmsItemGuestId?: number | null,
            serviceId: number | null = null,
            serviceNote: string = "",
            notifyGuest: boolean = false
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/pms/services/add", {
                    booking_id: bookingStore.state.booking.id,
                    booking_pms_item_id: bookingPmsItemId,
                    booking_pms_item_guest_id: bookingPmsItemGuestId,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    service_date: bookingStore.state.booking.start_date,
                    service_name: serviceName,
                    service_id: serviceId,
                    service_quantity: 1,
                    pms_folio_id: pmsFolioId,
                    service_note: serviceNote,
                });

                bookingStore.state.booking = response.data.data;

                if (notifyGuest) {
                    push.success({
                        title: "Booking Update",
                        message: `${serviceName} has been added to your booking.`,
                    });
                }

                if (response.data.analytics) {
                    emitEvent("analytics-event", {
                        type: "add_to_cart",
                        venue:
                            bookingStore.state.booking.venue_id === 100
                                ? "Hoar Cross Hall"
                                : "Eden Hall",
                        ecommerce: {
                            currency: "GBP",
                            add: {
                                products: [response.data.analytics.product],
                            },
                        },
                    });
                }

                return true;
            } catch (errorResponse: any) {
                logErrorDetails(errorResponse, "Error adding special service");

                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function removePmsSpecialService(
            bookingSpecialServiceItem: BookingSpecialServiceItem,
            bookingPmsItemId: number,
            pmsFolioId?: number | null,
            pmsFolioSpecialServiceId?: number,
            specialServiceId?: number
        ): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.post("api/pms/services/remove", {
                    booking_id: bookingStore.state.booking.id,
                    booking_pms_item_id: bookingPmsItemId,
                    webfolio_id: systemStore.state.webfolioId,
                    session_id: systemStore.state.sessionId,
                    pms_folio_id: pmsFolioId,
                    pms_folio_special_service_id: pmsFolioSpecialServiceId,
                    special_service_id: specialServiceId,
                });

                if (response) {
                    bookingStore.state.booking = response.data.data;

                    push.success({
                        title: "Booking Update",
                        message: `${bookingSpecialServiceItem.special_service.title} has been removed from your booking.`,
                    });

                    return true;
                } else {
                    return false;
                }
            } catch (errorResponse: any) {
                logErrorDetails(
                    errorResponse,
                    "Error removing special service"
                );

                const isSessionExpired =
                    errorResponse?.response?.data?.session_expired;

                if (isSessionExpired) {
                    push.error({
                        title: "Session Expired",
                        message:
                            "Your session has expired, please start your booking again.",
                    });

                    resetStore.all();
                    await systemStore.systemStatus();
                    await systemStore.createSession();
                    router.push({ name: "Index" });

                    return false;
                }

                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const logErrorDetails = (
            errorResponse: any,
            defaultMessage: string
        ) => {
            const errorMessage =
                errorResponse.response?.data?.message || defaultMessage;
            const errorStatus =
                errorResponse.response?.status || "Unknown status";
            const errorData =
                errorResponse.response?.data || "No additional data";

            console.error("Error Message:", errorMessage);
            console.error("Error Status:", errorStatus);
            console.error("Error Data:", errorData);

            // Capture the error in Sentry
            Sentry.captureException(new Error(errorMessage), {
                extra: {
                    status: errorStatus,
                    data: errorData,
                },
            });
        };

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            createPmsBooking,
            removePmsBooking,
            fetchSpecialServices,
            addPmsSpecialService,
            removePmsSpecialService,
            $reset,
        };
    },
    {
        persist: {
            storage: sessionStorage,
        },
    }
);
