<template>
    <div class="cart-item p-4 bg-cart">
        <div
            class="flex gap-3 justify-between items-center pb-2 border-b border-ivory-900"
        >
            <h2
                class="font-subheading font-semibold uppercase text-xs line-clamp-1"
                role="heading"
                aria-level="2"
            >
                {{ item.dining_type }} for
                {{ item.dining_reservation?.guests }} Guests
            </h2>
        </div>

        <div class="flex justify-between items-center text-sm mt-4">
            <div class="w-full flex gap-4">
                <div
                    class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                    style="
                        background-image: url('https://barons-eden-cdn.fra1.digitaloceanspaces.com/page/sections/image-text/QueYDDnVUoDwCcZ4vw0T8USQwUpmdFbXhszrsvtM.jpg');
                    "
                ></div>
                <div class="flex-auto flex flex-col items-start">
                    <p class="font-sans text-xs font-bold line-clamp-1">
                        {{ item.promotion_name }}
                    </p>
                    <p class="font-sans text-xxs line-clamp-1">
                        £{{ item.promotion_price }} Per Person | Booking Ref:
                        {{ item.dining_reservation.reference }}
                    </p>
                </div>
                <div class="flex flex-col justify-between items-end">
                    <p class="text-[14px] font-medium font-subheading">
                        £{{ item.total }}
                    </p>
                    <DynamicButton
                        @click="removeDiningReservation"
                        variant="text"
                        size="none"
                        aria-label="Remove dining item"
                    >
                        <span
                            class="text-danger-700 text-xs capitalize underline"
                        >
                            {{ $t("common.remove") }}
                        </span>
                    </DynamicButton>
                </div>
            </div>
        </div>
    </div>

    <PopupModel
        :visible="confirmationModalVisible"
        :title="$t('reviewBooking.confirmTitle')"
        @close="confirmationModalVisible = false"
    >
        {{ $t("reviewBooking.confirmMessage") }}
        <template #buttons>
            <DynamicButton
                variant="solid"
                @click="confirmRemoval(true)"
                additionalClasses="mt-4"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.remove") }}
            </DynamicButton>
            <DynamicButton
                variant="outline"
                @click="confirmRemoval(false)"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.cancel") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <LoadingLogo
        v-if="isLoading"
        :loading="isLoading"
        :message="$t('common.updatingBooking')"
    />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useDiningStore } from "@/stores/dining/dining";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";
import type { BookingDiningItem } from "@/types";
import IconWithText from "@/components/global/IconWithText.vue";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import ExperienceCartIcon from "@/components/icons/ExperienceCartIcon.vue";
import GuestIcon from "@/components/icons/GuestIcon.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";

interface Props {
    item: BookingDiningItem;
}

const bookingStepsStore = useBookingStepsStore();
const diningStore = useDiningStore();
const props = defineProps<Props>();
const confirmationModalVisible = ref(false);
const resolveConfirmation = ref<((isConfirmed: boolean) => void) | null>(null);
const isLoading = ref(false);

const formatTitle = (title: string, maxLength: number) => {
    return title.length > maxLength ? `${title.slice(0, maxLength)}…` : title;
};

const confirmRemoval = (isConfirmed: boolean) => {
    confirmationModalVisible.value = false;
    if (resolveConfirmation.value) {
        resolveConfirmation.value(isConfirmed);
    }
};

const showConfirmationModal = () => {
    confirmationModalVisible.value = true;

    return new Promise((resolve) => {
        resolveConfirmation.value = resolve;
    });
};

const removeDiningReservation = async () => {
    try {
        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await diningStore.removeDiningReservation();
        isLoading.value = false;
        await bookingStepsStore.resetToStep(1);
    } catch (error) {
        console.error("Error removing dining reservation", error);

        throw error;
    }
};
</script>
