<template>
    <Transition name="fade">
        <div
            v-show="visible"
            class="overlay"
            aria-hidden="true"
            @click="closeCart"
        ></div>
    </Transition>

    <Transition name="slide-left">
        <div
            v-show="visible"
            :class="[
                'shopping-cart fixed bottom-0 top-0 right-0 bg-ivory-550 shadow-lg z-[999] w-full h-100vh overflow-y-auto | md:max-w-96',
                cartClasses,
            ]"
            role="dialog"
            aria-modal="true"
            aria-labelledby="cart-title"
        >
            <div
                class="shopping-cart-container relative flex flex-col overflow-hidden h-full w-full"
            >
                <div
                    class="shopping-cart-inner px-4 py-2 flex flex-row-reverse justify-between items-center w-full bg-[#E5E3DD]"
                >
                    <DynamicButton
                        variant="text"
                        size="none"
                        additionalClasses="shopping-cart-close !gap-0"
                        @click="closeCart"
                        aria-label="Close cart"
                    >
                        <template #icon-before>
                            <CloseIcon />
                        </template>
                    </DynamicButton>
                    <BookingTitle
                        :heading="$t('shoppingCart.title')"
                        type="cart"
                        id="cart-title"
                    />
                </div>
                <div
                    class="shopping-cart-overview px-4 py-3 flex flex-initial flex-col w-full"
                    v-if="bookingStore.state.booking.cart_items > 0"
                >
                    <p
                        class="shopping-cart-overview-details font-sans flex justify-between items-center text-black-600 w-full"
                    >
                        <span
                            class="shopping-cart-overview-details-venue uppercase tracking-wider font-semibold"
                        >
                            {{ computedVenue }}
                        </span>

                        <span
                            class="shopping-cart-overview-details-dates"
                            v-if="
                                bookingStore.state.booking.experience !==
                                'gift_cards'
                            "
                        >
                            {{ formattedDateRange }}
                        </span>
                    </p>
                </div>

                <div
                    class="shopping-cart-items flex flex-col flex-initial gap-6 overflow-auto"
                >
                    <div
                        class="flex flex-col gap-4 items-center justify-center p-6"
                        v-if="bookingStore.state.booking.cart_items < 1"
                    >
                        <BookingTitle
                            heading="Your shopping cart is empty"
                            type="cart"
                        />
                        <DynamicButton
                            variant="outline"
                            color="secondary"
                            @click="closeCart"
                            aria-label="Continue shopping"
                        >
                            Continue Shopping
                        </DynamicButton>
                    </div>

                    <div
                        v-if="
                            bookingStore.state.booking.pms_items[0]
                                .rate_details &&
                            bookingStore.state.booking.pms_items &&
                            bookingStore.state.booking.pms_items.length > 0
                        "
                        class="pms-items flex flex-col gap-4"
                    >
                        <CartPmsItem
                            v-for="(item, index) in pmsItems"
                            :key="item.id || index"
                            :title="`${getCartItemTitle} ${index + 1}`"
                            :item="item"
                            :day-booking="bookingStore.isDayBooking()"
                        />
                    </div>

                    <div
                        v-if="
                            bookingStore.state.booking.treatment_items &&
                            bookingStore.state.booking.treatment_items.length >
                                0
                        "
                        class="treatment-items flex flex-col gap-4"
                    >
                        <CartTreatmentItem
                            v-for="(item, index) in bookingStore.state.booking
                                .treatment_items"
                            :key="item.id || index"
                            :title="`${getCartItemTitle} ${index + 1}`"
                            :item="item"
                        />
                    </div>

                    <div
                        v-if="
                            bookingStore.state.booking.retail_items &&
                            bookingStore.state.booking.retail_items.length > 0
                        "
                        class="retail-items flex flex-col gap-4"
                    >
                        <CartRetailItem
                            v-for="(item, index) in bookingStore.state.booking
                                .retail_items"
                            :key="item.id || index"
                            title="Gift Cards"
                            :item="item"
                        />
                    </div>

                    <div
                        v-if="
                            bookingStore.state.booking.dining_items &&
                            bookingStore.state.booking.dining_items.length > 0
                        "
                        class="dining-items flex flex-col gap-4"
                    >
                        <CartDiningItem
                            v-for="(item, index) in bookingStore.state.booking
                                .dining_items"
                            :key="item.id || index"
                            title="Dining"
                            :item="item"
                        />
                    </div>
                </div>

                <div
                    class="shopping-cart-total bg-[#E5E3DD] mt-auto px-4 flex flex-initial py-2 flex-col"
                >
                    <div
                        v-if="
                            bookingStore.state.booking.early_spa_access_total >
                            0
                        "
                        class="flex justify-between items-center pb-2"
                    >
                        <h2
                            class="font-subheading font-semibold uppercase text-sm line-clamp-1"
                            role="heading"
                            aria-level="2"
                        >
                            {{ $t("common.spaAccessTitle") }}
                        </h2>
                        <div class="flex gap-2">
                            <p
                                class="font-subheading text-[16px] font-semibold ml-auto"
                                aria-live="polite"
                            >
                                £{{
                                    bookingStore.state.booking
                                        .early_spa_access_total
                                }}
                            </p>
                            <DynamicButton
                                v-if="
                                    bookingStore.state.booking
                                        .early_spa_access_total > 0
                                "
                                @click="removeEarlySpaAccess()"
                                variant="text"
                                size="none"
                                aria-label="Remove early spa access"
                            >
                                <span
                                    class="text-danger-700 text-xs capitalize underline"
                                >
                                    {{ $t("common.remove") }}
                                </span>
                            </DynamicButton>
                        </div>
                    </div>

                    <div
                        v-if="bookingStore.state.booking.discount_total < 0"
                        class="flex gap-3 justify-between items-center pb-2"
                    >
                        <h2
                            class="font-subheading font-semibold uppercase text-sm line-clamp-1"
                            role="heading"
                            aria-level="2"
                        >
                            {{ $t("common.discount") }}
                        </h2>
                        <p
                            class="font-subheading text-[16px] font-semibold"
                            aria-live="polite"
                        >
                            £{{ bookingStore.state.booking.discount_total }}
                        </p>
                    </div>

                    <div
                        v-if="bookingStore.state.booking.gift_card_total < 0"
                        class="flex gap-3 justify-between items-center pb-2"
                    >
                        <h2
                            class="font-subheading font-semibold uppercase text-sm line-clamp-1"
                            role="heading"
                            aria-level="2"
                        >
                            {{ $t("common.giftCards") }}
                        </h2>
                        <p
                            class="font-subheading text-[16px] font-semibold"
                            aria-live="polite"
                        >
                            £{{ bookingStore.state.booking.gift_card_total }}
                        </p>
                    </div>

                    <div
                        v-if="bookingStore.state.booking.insurance_total > 0"
                        class="flex gap-3 justify-between items-center pb-2"
                    >
                        <h2
                            class="font-subheading font-semibold uppercase text-sm line-clamp-1"
                            role="heading"
                            aria-level="2"
                        >
                            {{ $t("common.insurance") }}
                        </h2>
                        <p
                            class="font-subheading text-[16px] font-semibold"
                            aria-live="polite"
                        >
                            £{{ bookingStore.state.booking.insurance_total }}
                        </p>
                        <DynamicButton
                            v-if="
                                bookingStore.state.booking.insurance_total > 0
                            "
                            @click="removeFlexInsurance()"
                            variant="text"
                            size="none"
                            aria-label="Remove early spa access"
                        >
                            <span
                                class="text-danger-700 text-xs capitalize underline"
                            >
                                {{ $t("common.remove") }}
                            </span>
                        </DynamicButton>
                    </div>

                    <div class="flex justify-between items-center gap-4">
                        <p
                            class="shopping-cart-total-title font-subheading font-bold text-sm uppercase text-black-600"
                            aria-live="polite"
                        >
                            {{ $t("common.total") }}
                        </p>
                        <p
                            class="shopping-cart-total-price font-subheading text-xl text-black-900 font-bold"
                            aria-live="polite"
                        >
                            &pound;{{ bookingStore.state.booking.total }}
                        </p>
                    </div>
                </div>

                <DynamicButton
                    v-if="bookingStore.state.booking"
                    variant="solid"
                    size="large"
                    type="button"
                    additionalClasses="shopping-cart-checkout-button  flex-initial m-4"
                    color="secondary"
                    aria-label="Checkout"
                    @click="goToCheckout"
                >
                    <template #icon-before>
                        <CartIcon />
                    </template>
                    {{ $t("common.checkout") }}
                </DynamicButton>
            </div>
        </div>
    </Transition>

    <LoadingLogo
        v-if="isLoading"
        :loading="isLoading"
        :message="$t('common.updatingBooking')"
    />
</template>

<script setup lang="ts">
import DynamicButton from "@/components/forms/DynamicButton.vue";
import BookingTitle from "@/components/global/BookingTitle.vue";
import CartDiningItem from "@/components/global/CartDiningItem.vue";
import CartPmsItem from "@/components/global/CartPmsItem.vue";
import CartRetailItem from "@/components/global/CartRetailItem.vue";
import CartTreatmentItem from "@/components/global/CartTreatmentItem.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";
import CartIcon from "@/components/icons/CartIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import router from "@/router";
import { useBookingStore } from "@/stores/booking/booking";
import type { BookingPmsItem } from "@/types";
import { computed, ref } from "vue";

interface Props {
    visible: boolean;
    cartClasses?: string;
    venue?: string;
    experience?: string;
    dates?: string;
    adults?: number;
    rooms?: number;
    total?: string | number;
}

const props = defineProps<Props>();
const bookingStore = useBookingStore();
const emit = defineEmits(["update:visible"]);
const isLoading = ref(false);

const computedVenue = computed(() => {
    return bookingStore.state.booking.venue_id === 100
        ? "Hoar Cross Hall"
        : "Eden Hall";
});

const getCartItemTitle = computed(() => {
    return bookingStore.state.booking.experience === "spa_day"
        ? "Guest"
        : "Room";
});

const pmsItems = computed(() => {
    return bookingStore.state.booking.pms_items as BookingPmsItem[];
});

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });

    const ordinalSuffix = (n: number) => {
        const s = ["th", "st", "nd", "rd"];
        const v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };

    return `${ordinalSuffix(day)} ${month}`;
};

const formattedDateRange = computed(() => {
    const start = formatDate(bookingStore.state.booking.start_date);
    const end = formatDate(bookingStore.state.booking.end_date).split(" ")[0];

    if (bookingStore.isDayBooking()) {
        return `${start.split(" ")[0]} ${start.split(" ")[1]}`;
    }

    return `${start.split(" ")[0]} - ${end} ${start.split(" ")[1]}`;
});

const closeCart = () => {
    emit("update:visible", false);
};

const goToCheckout = () => {
    emit("update:visible", false);

    router.push({ name: "Checkout" });
};

const removeEarlySpaAccess = async () => {
    isLoading.value = true;

    try {
        await bookingStore.removeEarlyAccess();
        closeCart();
    } catch (error) {
        console.error("Error removing early spa access", error);
        throw error;
    } finally {
        isLoading.value = false;
    }
};

const removeFlexInsurance = async () => {
    isLoading.value = true;

    try {
        await bookingStore.removeFlexInsurance();
        closeCart();
    } catch (error) {
        console.error("Error removing flex insurance", error);
        throw error;
    } finally {
        isLoading.value = false;
    }
};
</script>

<style scoped lang="scss">
.shopping-cart {
    &-close {
        @apply text-xs;
    }

    &-title {
        @apply text-center w-full;
    }

    &-overview-title,
    &-overview-details {
        @apply text-sm;
    }

    &-checkout-button {
        // @apply bg-secondary text-white;
    }
}

.spaaccess {
    @apply md:flex-col md:items-start;
}
</style>
