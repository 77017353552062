<template>
    <Transition name="fade">
        <div
            v-show="visible"
            class="overlay z-[998]"
            aria-hidden="true"
            @click="closeModal"
        ></div>
    </Transition>

    <div
        v-if="visible"
        class="fixed inset-0 flex items-center justify-center z-[999] overflow-y-auto"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        tabindex="-1"
        ref="modalContainer"
        :id="id"
    >
        <div
            :class="[
                'relative p-5 shadow-md border border-stone-100 bg-ivory-900 z-[100]',
                modalWidthClass,
            ]"
            style="max-height: 90vh; display: flex; flex-direction: column"
        >
            <span
                @click="closeModal"
                class="absolute right-2 top-2 text-primary"
                aria-label="Clear"
            >
                <CloseIcon />
            </span>
            <div class="overflow-y-auto" style="max-height: calc(90vh - 100px)">
                <div class="mt-3 text-center">
                    <h3
                        class="text-lg leading-6 font-medium text-primary with-html"
                        id="modal-title"
                        role="heading"
                        aria-level="3"
                        v-if="useHtml"
                        v-html="title"
                    ></h3>
                    <h3
                        class="text-lg leading-6 font-medium text-primary"
                        id="modal-title"
                        role="heading"
                        aria-level="3"
                        v-else
                    >
                        {{ title }}
                    </h3>
                    <div class="mt-2 px-7 py-3" id="modal-description">
                        <p class="text-sm text-textcolor">
                            <slot></slot>
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col items-center gap-2 px-4 py-3 mt-4">
                <slot name="buttons"></slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CloseIcon from "@/components/icons/CloseIcon.vue";
import {
    computed,
    nextTick,
    onBeforeUnmount,
    onMounted,
    ref,
    watch,
} from "vue";

interface Props {
    visible: boolean;
    title: string;
    size?: "small" | "medium" | "large" | "xlarge";
    useHtml?: boolean;
    id?: string;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:visible"]);
const modalContainer = ref<HTMLElement | null>(null);

const useHtml = props.useHtml || false;

const closeModal = () => {
    emit("update:visible", false);
};

const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
        closeModal();
    }
};

const setFocus = () => {
    if (modalContainer.value) {
        modalContainer.value.focus();
    }
};

onMounted(() => {
    if (props.visible) {
        setFocus();
        document.addEventListener("keydown", handleEscape);
    }
});

onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleEscape);
});

watch(
    () => props.visible,
    (newVal) => {
        if (newVal) {
            nextTick(() => setFocus());
            document.addEventListener("keydown", handleEscape);
        } else {
            document.removeEventListener("keydown", handleEscape);
        }
    }
);

const modalWidthClass = computed(() => {
    switch (props.size) {
        case "small":
            return "w-64";
        case "large":
            return "w-[32rem]";
        case "xlarge":
            return "w-[56rem]";
        case "medium":
        default:
            return "w-[90vw] mx-auto sm:w-96";
    }
});
</script>

<style scoped lang="scss">
:deep(button) {
    @apply w-full;
}
</style>
