<template>
    <div class="cart-item p-4 bg-cart flex flex-col gap-2">
        <div
            class="flex justify-between items-center pb-2 border-b border-ivory-900"
        >
            <h2
                class="font-subheading font-bold uppercase text-[12px]"
                role="heading"
                aria-level="2"
            >
                {{ $t("cartItem.giftCardLabel") }}
            </h2>
        </div>

        <div
            class="flex justify-between items-center text-sm pb-2 border-b border-ivory-900"
        >
            <div class="w-full flex gap-4">
                <div
                    class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                    style="
                        background-image: url('https://barons-eden-cdn.fra1.digitaloceanspaces.com/giftcards/rJXJXb9H5rMcEFLTfykBB3v6bx5V1N0teQqfz2Te.jpg');
                    "
                ></div>
                <div class="flex-auto flex flex-col items-start">
                    <p class="font-sans text-xs font-bold line-clamp-1">
                        {{ formatTitle(item.gift_card.name, 30) }}
                    </p>
                    <p class="font-sans text-xxs line-clamp-1">
                        {{ formatTitle(item.gift_card.item_type, 30) }}
                    </p>
                </div>
                <div class="flex flex-col justify-between items-end">
                    <p class="text-[14px] font-medium font-subheading">
                        £{{ Number(item.total) - Number(item.shipping.price) }}
                    </p>
                    <DynamicButton
                        @click="removeGiftCard(item)"
                        variant="text"
                        size="none"
                        aria-label="Remove retail item"
                    >
                        <span
                            class="text-danger-700 text-xs font-bold capitalize underline"
                        >
                            {{ $t("common.removeGC") }}
                        </span>
                    </DynamicButton>
                </div>
            </div>
        </div>

        <div
            class="flex flex-col justify-between items-center text-sm border-b border-ivory-900 gap-2"
            v-if="
                item.shipping.shipping_id === 103014 ||
                item.shipping.shipping_id === 103016
            "
        >
            <div class="w-full flex justify-between items-center">
                <h2
                    class="font-subheading font-bold uppercase text-[12px]"
                    role="heading"
                    aria-level="2"
                >
                    {{ $t("cartItem.giftSetLabel") }}
                </h2>
            </div>

            <div class="w-full flex gap-4 pb-2 border-b border-ivory-900">
                <!-- <div
                    v-if="item.gift_card?.image"
                    class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                    :style="{
                        backgroundImage: `url(${item.gift_card?.image})`,
                    }"
                ></div> -->
                <div class="flex-auto flex flex-col items-start">
                    <p class="font-sans text-xs font-bold line-clamp-1">
                        {{ formatTitle(item.shipping.name, 20) }}
                    </p>
                </div>
                <div class="flex flex-col justify-between items-end">
                    <p class="text-[14px] font-medium font-subheading">
                        £{{ item.shipping.price }}
                    </p>
                </div>
            </div>

            <div class="w-full flex justify-between items-center">
                <p class="font-subheading font-bold uppercase text-[12px]">
                    {{ $t("cartItem.shippingLabel") }}
                </p>
            </div>

            <div class="w-full flex pb-2 gap-4">
                <!-- <div
                    v-if="item.gift_card?.image"
                    class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                    :style="{
                        backgroundImage: `url(${item.gift_card?.image})`,
                    }"
                ></div> -->
                <div class="flex-auto flex flex-col items-start">
                    <p class="font-sans text-xs font-bold line-clamp-1">
                        1st Class Post
                    </p>
                </div>
                <div class="flex flex-col justify-between items-end">
                    <p class="text-[14px] font-medium font-subheading">FREE</p>
                </div>
            </div>
        </div>

        <div
            v-if="
                item.shipping.shipping_id !== 103014 &&
                item.shipping.shipping_id !== 103016
            "
        >
            <p class="font-subheading font-bold uppercase text-[12px] mb-2">
                {{ $t("cartItem.shippingLabel") }}
            </p>

            <div class="flex justify-between items-center mb-4 text-sm w-full">
                <ul class="w-full">
                    <li class="flex justify-between gap-2" role="listitem">
                        <span
                            class="flex-auto flex flex-col font-subheading text-[12px] tracking-tight leading-5 line-clamp-1"
                        >
                            {{ formatTitle(item.shipping.name, 20) }}
                        </span>
                        <span class="text-[14px] font-medium font-subheading"
                            >£{{ item.shipping.price }}</span
                        >
                    </li>
                </ul>
            </div>
        </div>

        <p
            class="font-subheading text-[16px] flex justify-between items-center font-semibold"
            aria-live="polite"
        >
            <span class="text-xs font-bold">TOTAL COST:</span>
            £{{ item.total }}
        </p>
    </div>

    <PopupModel
        :visible="confirmationModalVisible"
        :title="$t('reviewBooking.confirmTitle')"
        @close="confirmationModalVisible = false"
    >
        {{ $t("reviewBooking.confirmMessage") }}
        <template #buttons>
            <DynamicButton
                variant="solid"
                @click="confirmRemoval(true)"
                additionalClasses="mt-4"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.remove") }}
            </DynamicButton>
            <DynamicButton
                variant="outline"
                @click="confirmRemoval(false)"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.cancel") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <LoadingLogo
        v-if="isLoading"
        :loading="isLoading"
        :message="$t('common.updatingBooking')"
    />
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { BookingRetailItem } from "@/types";
import { useRetailStore } from "@/stores/retail/retail";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";
import { useBookingStore } from "@/stores/booking/booking";

interface Props {
    item: BookingRetailItem;
}

const props = defineProps<Props>();
const bookingStore = useBookingStore();
const bookingStepsStore = useBookingStepsStore();
const retailStore = useRetailStore();
const confirmationModalVisible = ref(false);
const resolveConfirmation = ref<((isConfirmed: boolean) => void) | null>(null);
const isLoading = ref(false);

const confirmRemoval = (isConfirmed: boolean) => {
    confirmationModalVisible.value = false;
    if (resolveConfirmation.value) {
        resolveConfirmation.value(isConfirmed);
    }
};

const showConfirmationModal = () => {
    confirmationModalVisible.value = true;

    return new Promise((resolve) => {
        resolveConfirmation.value = resolve;
    });
};

const formatTitle = (title: string, maxLength: number) => {
    if (!title) {
        return "";
    }

    return title.trim().length > maxLength
        ? title.trim().slice(0, maxLength) + "…"
        : title.trim();
};

const removeGiftCard = async (retailItem: BookingRetailItem) => {
    try {
        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await retailStore.removeGiftCard(
            retailItem.retail_folio_id,
            retailItem.retail_folio_item_id
        );

        if (bookingStore.state.booking.retail_items.length === 0) {
            await bookingStepsStore.resetToStep(1);
        }

        isLoading.value = false;
    } catch (error) {
        console.error("Error during treatment removal", error);

        throw error;
    }
};
</script>
