<template>
    <div class="cart-item p-4 bg-cart" v-if="item.pms_folio_id">
        <div
            class="flex gap-3 justify-between items-center pb-2 border-b border-ivory-900"
        >
            <h2
                class="font-subheading font-semibold uppercase text-xs line-clamp-1"
                role="heading"
                aria-level="2"
            >
                {{ dayBooking ? item.rate_details?.title : title }}
            </h2>
        </div>

        <div v-if="!dayBooking">
            <div
                class="flex justify-between items-center mb-4 text-sm mt-4 pb-4 border-b border-ivory-900"
            >
                <div class="w-full flex gap-4">
                    <div
                        v-if="item.room_details?.image"
                        class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                        :style="{
                            backgroundImage: `url(${item.room_details?.image})`,
                        }"
                    ></div>
                    <div class="flex-auto flex flex-col items-start">
                        <p class="font-sans text-xs font-bold line-clamp-1">
                            {{ item.rate_details?.title }}
                        </p>
                        <p class="font-sans text-xxs line-clamp-1">
                            {{ item.room_details?.title }}
                            <span
                                v-if="
                                    item.special_services &&
                                    item.special_services.length >= 1
                                "
                            >
                                <span
                                    v-for="(
                                        service, index
                                    ) in item.special_services"
                                    :key="index"
                                >
                                    | {{ service.special_service?.title }}
                                </span>
                            </span>
                            | {{ item.guests?.length }}
                            {{ $t("common.guests") }},
                            {{ bookingStore.state.booking.nights }} Night(s)
                        </p>
                        <DynamicButton
                            v-if="item.rate_details?.features"
                            variant="text"
                            size="none"
                            aria-label="view details"
                            class="mt-auto"
                            @click="showDetails = !showDetails"
                        >
                            <span
                                class="text-stone-900 text-xs capitalize underline"
                            >
                                {{ $t("common.viewDetails") }}
                            </span>
                        </DynamicButton>
                    </div>
                    <div class="flex flex-col justify-between items-end">
                        <p class="text-[14px] font-medium font-subheading">
                            £{{ item.total }}
                        </p>
                        <DynamicButton
                            v-if="item.pms_folio_id"
                            @click="removePmsItem"
                            variant="text"
                            size="none"
                            aria-label="Remove PMS item"
                        >
                            <span
                                class="text-danger-700 text-xs font-bold capitalize underline"
                            >
                                {{ $t(removeItemText) }}
                            </span>
                        </DynamicButton>
                    </div>
                </div>
            </div>
            <Transition name="slide-down" v-if="item.rate_details?.features">
                <div
                    v-show="showDetails"
                    class="w-full flex flex-col gap-4 overflow-scroll pb-4"
                >
                    <div
                        class="flex flex-inline gap-4 border-b border-ivory-900 pb-4"
                    >
                        <h4 class="min-w-20 flex-initial text-xs font-bold">
                            Features
                        </h4>
                        <div class="flex flex-col gap-2">
                            <dl
                                class="flex gap-2 items-center justify-start"
                                v-for="(feature, index) in item.rate_details
                                    ?.features"
                                :key="index"
                            >
                                <dt class="">
                                    <img
                                        class="w-6 h-6"
                                        :src="feature.icon"
                                        :alt="feature.title"
                                    />
                                </dt>
                                <dd class="font-sans text-xxs line-clamp-1">
                                    {{ feature.title }}
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div
                        class="flex flex-inline gap-4 border-b border-ivory-900 pb-4"
                    >
                        <h4 class="min-w-20 flex-initial text-xs font-bold">
                            Amenities
                        </h4>
                        <div class="flex flex-col gap-2">
                            <dl
                                class="flex gap-2 items-center justify-start"
                                v-for="(amenity, index) in item.room_details
                                    ?.amenities"
                                :key="index"
                            >
                                <dt class="">
                                    <img
                                        class="w-6 h-6"
                                        :src="amenity.icon"
                                        :alt="amenity.title"
                                    />
                                </dt>
                                <dd class="font-sans text-xxs line-clamp-1">
                                    {{ amenity.title }}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </Transition>

            <div
                class="mb-2 flex flex-col gap-4 inc"
                v-if="(item.inclusive_treatments ?? []).length > 0"
            >
                <p class="font-subheading font-bold uppercase text-xs">
                    {{ $t("cartItem.inclusiveTreatmentsLabelSimple") }}
                </p>
                <div v-for="(guest, index) in item.guests" :key="index">
                    <div
                        v-if="guest.inclusive_treatments.length > 0"
                        class="flex flex-col gap-2 border-b border-ivory-900 pb-4"
                    >
                        <div
                            v-for="(item, index) in guest.inclusive_treatments"
                            :key="index"
                            class="flex flex-col"
                            role="listitem"
                        >
                            <div class="w-full flex gap-4">
                                <div
                                    v-if="item.treatment?.image"
                                    class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                                    :style="{
                                        backgroundImage: `url(${item.treatment?.image})`,
                                    }"
                                ></div>
                                <div class="flex flex-col items-start">
                                    <p
                                        class="font-sans text-xs font-bold line-clamp-1"
                                    >
                                        {{
                                            truncateText(
                                                item.treatment.custom_title ??
                                                    item.treatment.title,
                                                35
                                            )
                                        }}
                                    </p>
                                    <p class="text-xxs">
                                        <span
                                            class="font-bold"
                                            v-if="guest.first_name"
                                        >
                                            {{ guest.first_name }} |
                                        </span>
                                        {{ item.treatment?.service_time }}
                                        Mins |
                                        {{
                                            new Date(
                                                item.start_time
                                            ).toLocaleString("en-GB", {
                                                weekday: "long",
                                            })
                                        }}
                                        {{
                                            new Date(
                                                item.start_time
                                            ).toLocaleTimeString("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })
                                        }}
                                        -
                                        {{
                                            new Date(
                                                new Date(
                                                    item.start_time
                                                ).getTime() +
                                                    item.treatment
                                                        .service_time *
                                                        60000
                                            ).toLocaleTimeString("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="flex flex-col justify-between items-end"
                                >
                                    <p
                                        class="text-[14px] font-medium font-subheading"
                                    >
                                        &nbsp;
                                    </p>
                                    <DynamicButton
                                        @click="
                                            removeTreatment(
                                                guest.id,
                                                item,
                                                true
                                            )
                                        "
                                        variant="text"
                                        size="none"
                                        aria-label="Remove inclusive treatment"
                                    >
                                        <span
                                            class="text-danger-700 text-xs font-bold capitalize underline"
                                        >
                                            {{ $t("common.remove") }}
                                        </span>
                                    </DynamicButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="mb-2 flex flex-col gap-4 add"
                v-if="(item.additional_treatments ?? []).length > 0"
            >
                <p class="font-subheading font-bold uppercase text-xs">
                    {{ $t("cartItem.additionalsLabelSimple") }}
                </p>
                <div v-for="(guest, index) in item.guests" :key="index">
                    <div
                        v-if="guest.additional_treatments.length > 0"
                        class="flex flex-col gap-2 border-b border-ivory-900 pb-4"
                    >
                        <div
                            v-for="(item, index) in guest.additional_treatments"
                            :key="index"
                            class="flex flex-col"
                            role="listitem"
                        >
                            <div class="w-full flex gap-4">
                                <div
                                    v-if="item.treatment?.image"
                                    class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                                    :style="{
                                        backgroundImage: `url(${item.treatment?.image})`,
                                    }"
                                ></div>
                                <div class="flex flex-col items-start">
                                    <p
                                        class="font-sans text-xs font-bold line-clamp-1"
                                    >
                                        {{
                                            truncateText(
                                                item.treatment.custom_title ??
                                                    item.treatment.title,
                                                35
                                            )
                                        }}
                                    </p>
                                    <p class="text-xxs">
                                        <span
                                            class="font-bold"
                                            v-if="guest.first_name"
                                        >
                                            {{ guest.first_name }} |
                                        </span>
                                        {{ item.treatment?.service_time }}
                                        Mins |
                                        {{
                                            new Date(
                                                item.start_time
                                            ).toLocaleString("en-GB", {
                                                weekday: "long",
                                            })
                                        }}
                                        {{
                                            new Date(
                                                item.start_time
                                            ).toLocaleTimeString("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })
                                        }}
                                        -
                                        {{
                                            new Date(
                                                new Date(
                                                    item.start_time
                                                ).getTime() +
                                                    item.treatment
                                                        .service_time *
                                                        60000
                                            ).toLocaleTimeString("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="flex flex-col justify-between items-end"
                                >
                                    <p
                                        class="text-[14px] font-medium font-subheading"
                                    >
                                        £{{ item.treatment.price }}
                                    </p>
                                    <DynamicButton
                                        @click="
                                            removeTreatment(
                                                guest.id,
                                                item,
                                                true
                                            )
                                        "
                                        variant="text"
                                        size="none"
                                        aria-label="Remove inclusive treatment"
                                    >
                                        <span
                                            class="text-danger-700 text-xs font-bold capitalize underline"
                                        >
                                            {{ $t("common.remove") }}
                                        </span>
                                    </DynamicButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="mb-4"
                v-for="(guest, index) in item.guests"
                :key="guest.id"
            >
                <div v-if="guest.special_services.length > 0">
                    <p
                        v-if="index === 0"
                        class="font-subheading font-bold uppercase text-sm mb-2"
                    >
                        {{ $t("cartItem.finishingTouchesLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(item, index) in guest.special_services"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                {{
                                    truncateText(item.special_service.title, 20)
                                }}
                            </span>
                            <span
                                class="text-[14px] font-medium font-subheading"
                                >£{{ item.total }}</span
                            >

                            <DynamicButton
                                @click="removeSpecialService(item)"
                                variant="text"
                                size="none"
                                aria-label="Remove special service"
                            >
                                <span
                                    class="text-danger-700 text-xs capitalize underline"
                                >
                                    {{ $t("common.remove") }}
                                </span>
                            </DynamicButton>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mb-4">
                <div
                    v-if="
                        item.special_services &&
                        item.special_services.length > 0 &&
                        item.special_services[0].special_service
                    "
                >
                    <p class="font-subheading font-bold uppercase text-xs mb-2">
                        {{ $t("cartItem.finishingTouchesLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(
                                specialService, index
                            ) in item.special_services"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                {{
                                    truncateText(
                                        specialService.special_service.title,
                                        20
                                    )
                                }}
                            </span>
                            <div
                                class="flex flex-row-reverse items-center gap-2 items-end"
                            >
                                <DynamicButton
                                    @click="
                                        removeSpecialService(specialService)
                                    "
                                    variant="text"
                                    size="none"
                                    aria-label="Remove special service"
                                >
                                    <span
                                        class="text-danger-700 text-xs capitalize underline"
                                    >
                                        {{ $t("common.remove") }}
                                    </span>
                                </DynamicButton>
                                <span
                                    class="text-[14px] font-medium font-subheading"
                                    >£{{ specialService.total }}</span
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-else>
            <div
                class="flex justify-between items-center mb-4 text-sm mt-4 pb-4 border-b border-ivory-900"
            >
                <div class="w-full flex gap-4">
                    <div
                        v-if="item.rate_details?.image"
                        class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                        :style="{
                            backgroundImage: `url(${item.rate_details?.image})`,
                        }"
                    ></div>
                    <div class="flex-auto flex flex-col items-start">
                        <p class="font-sans text-xs font-bold line-clamp-1">
                            {{ item.rate_details?.title }}
                        </p>
                        <p class="font-sans text-xxs line-clamp-1">
                            {{ item.adults }} {{ $t("common.guest") }}
                        </p>
                        <DynamicButton
                            v-if="item.rate_details?.features"
                            variant="text"
                            size="none"
                            aria-label="view details"
                            class="mt-auto"
                            @click="showDetails = !showDetails"
                        >
                            <span
                                class="text-stone-900 text-xs capitalize underline"
                            >
                                {{ $t("common.viewDetails") }}
                            </span>
                        </DynamicButton>
                    </div>
                    <div class="flex flex-col justify-between items-end">
                        <p class="text-[14px] font-medium font-subheading">
                            £{{ item.total }}
                        </p>
                        <DynamicButton
                            v-if="item.pms_folio_id"
                            @click="removePmsItem"
                            variant="text"
                            size="none"
                            aria-label="Remove PMS item"
                        >
                            <span
                                class="text-danger-700 text-xs font-bold capitalize underline"
                            >
                                {{ $t(removeItemText) }}
                            </span>
                        </DynamicButton>
                    </div>
                </div>
            </div>

            <Transition name="slide-down" v-if="item.rate_details?.features">
                <div
                    v-show="showDetails"
                    class="w-full flex flex-col gap-4 overflow-scroll pb-4"
                >
                    <div
                        class="flex flex-inline gap-4 border-b border-ivory-900 pb-4"
                    >
                        <h4 class="min-w-20 flex-initial text-xs font-bold">
                            Features
                        </h4>
                        <div class="flex flex-col gap-2">
                            <dl
                                class="flex gap-2 items-center justify-start"
                                v-for="(feature, index) in item.rate_details
                                    ?.features"
                                :key="index"
                            >
                                <dt class="">
                                    <img
                                        class="w-6 h-6"
                                        :src="feature.icon"
                                        :alt="feature.title"
                                    />
                                </dt>
                                <dd class="font-sans text-xxs line-clamp-1">
                                    {{ feature.title }}
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </Transition>

            <div
                class="mb-2 flex flex-col gap-4 inc"
                v-if="
                    item.inclusive_treatments &&
                    item.inclusive_treatments.length > 0
                "
            >
                <div>
                    <p class="font-subheading font-bold uppercase text-xs mb-2">
                        {{ $t("cartItem.inclusiveTreatmentsLabelSimple") }}
                    </p>

                    <div class="flex flex-col gap-2">
                        <div
                            v-for="(
                                inclusiveItem, index
                            ) in item.inclusive_treatments"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <div class="w-full flex gap-4">
                                <div
                                    v-if="inclusiveItem.treatment?.image"
                                    class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                                    :style="{
                                        backgroundImage: `url(${inclusiveItem.treatment?.image})`,
                                    }"
                                ></div>
                                <div class="flex flex-col items-start">
                                    <p
                                        class="font-sans text-xs font-bold line-clamp-1"
                                    >
                                        {{
                                            truncateText(
                                                inclusiveItem.treatment
                                                    .custom_title ??
                                                    inclusiveItem.treatment
                                                        .title,
                                                35
                                            )
                                        }}
                                    </p>
                                    <p class="text-xxs">
                                        {{
                                            inclusiveItem.treatment
                                                ?.service_time
                                        }}
                                        Mins |
                                        {{
                                            new Date(
                                                inclusiveItem.start_time
                                            ).toLocaleString("en-GB", {
                                                weekday: "long",
                                            })
                                        }}
                                        {{
                                            new Date(
                                                inclusiveItem.start_time
                                            ).toLocaleTimeString("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })
                                        }}
                                        -
                                        {{
                                            new Date(
                                                new Date(
                                                    inclusiveItem.start_time
                                                ).getTime() +
                                                    inclusiveItem.treatment
                                                        .service_time *
                                                        60000
                                            ).toLocaleTimeString("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="flex flex-col justify-between items-end"
                                >
                                    <p
                                        class="text-[14px] font-medium font-subheading"
                                    >
                                        &nbsp;
                                    </p>
                                    <DynamicButton
                                        @click="
                                            removeTreatment(
                                                null,
                                                inclusiveItem,
                                                true
                                            )
                                        "
                                        variant="text"
                                        size="none"
                                        aria-label="Remove inclusive treatment"
                                    >
                                        <span
                                            class="text-danger-700 text-xs font-bold capitalize underline"
                                        >
                                            {{ $t("common.remove") }}
                                        </span>
                                    </DynamicButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="mb-2 flex flex-col gap-4 add"
                v-if="
                    item.additional_treatments &&
                    item.additional_treatments.length > 0
                "
            >
                <div>
                    <p class="font-subheading font-bold uppercase text-xs mb-2">
                        {{ $t("cartItem.additionalsLabelSimple") }}
                    </p>
                    <div class="flex flex-col gap-2">
                        <div
                            v-for="(
                                additionalItem, index
                            ) in item.additional_treatments"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <div class="w-full flex gap-4">
                                <div
                                    v-if="additionalItem.treatment?.image"
                                    class="bg-cover bg-center rounded-lg h-20 min-w-20 flex-initial"
                                    :style="{
                                        backgroundImage: `url(${additionalItem.treatment?.image})`,
                                    }"
                                ></div>
                                <div class="flex flex-col items-start">
                                    <p
                                        class="font-sans text-xs font-bold line-clamp-1"
                                    >
                                        {{
                                            truncateText(
                                                additionalItem.treatment
                                                    .custom_title ??
                                                    additionalItem.treatment
                                                        .title,
                                                35
                                            )
                                        }}
                                    </p>
                                    <p class="text-xxs">
                                        {{
                                            additionalItem.treatment
                                                ?.service_time
                                        }}
                                        Mins |
                                        {{
                                            new Date(
                                                additionalItem.start_time
                                            ).toLocaleString("en-GB", {
                                                weekday: "long",
                                            })
                                        }}
                                        {{
                                            new Date(
                                                additionalItem.start_time
                                            ).toLocaleTimeString("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })
                                        }}
                                        -
                                        {{
                                            new Date(
                                                new Date(
                                                    additionalItem.start_time
                                                ).getTime() +
                                                    additionalItem.treatment
                                                        .service_time *
                                                        60000
                                            ).toLocaleTimeString("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                            })
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="flex flex-col justify-between items-end"
                                >
                                    <p
                                        class="text-[14px] font-medium font-subheading"
                                    >
                                        £{{ additionalItem.treatment.price }}
                                    </p>
                                    <DynamicButton
                                        @click="
                                            removeTreatment(
                                                null,
                                                additionalItem,
                                                true
                                            )
                                        "
                                        variant="text"
                                        size="none"
                                        aria-label="Remove inclusive treatment"
                                    >
                                        <span
                                            class="text-danger-700 text-xs font-bold capitalize underline"
                                        >
                                            {{ $t("common.remove") }}
                                        </span>
                                    </DynamicButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="mb-4 special-services"
                v-if="item.special_services && item.special_services.length > 0"
            >
                <div>
                    <p class="font-subheading font-bold uppercase text-xs mb-2">
                        {{ $t("cartItem.finishingTouchesLabelSimple") }}
                    </p>
                    <ul class="flex flex-col gap-2">
                        <li
                            v-for="(
                                specialItem, index
                            ) in item.special_services"
                            :key="index"
                            class="flex justify-between gap-2"
                            role="listitem"
                        >
                            <span
                                class="flex-auto flex flex-col font-serif text-sm tracking-tight leading-5 line-clamp-1"
                            >
                                {{
                                    truncateText(
                                        specialItem.special_service.title,
                                        20
                                    )
                                }}
                            </span>
                            <div class="flex items-center gap-2">
                                <span
                                    class="text-[14px] font-medium font-subheading"
                                    >£{{ specialItem.total }}</span
                                >

                                <DynamicButton
                                    @click="removeSpecialService(specialItem)"
                                    variant="text"
                                    size="none"
                                    aria-label="Remove special service"
                                >
                                    <span
                                        class="text-danger-700 text-xs capitalize underline"
                                    >
                                        {{ $t("common.remove") }}
                                    </span>
                                </DynamicButton>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="mb-4" v-if="item.promo_items.length > 0">
            <p class="font-subheading font-bold uppercase text-sm mb-2">
                {{ $t("common.discount") }}
            </p>
            <ul>
                <li
                    v-for="(promoItem, index) in item.promo_items"
                    :key="index"
                    class="flex justify-between gap-2"
                    role="listitem"
                >
                    <span class="flex-auto"
                        >Promo code - {{ promoItem.title }}</span
                    >
                    <span v-if="promoItem.total != 0"
                        >£{{ promoItem.total }}</span
                    >
                </li>
            </ul>
        </div>

        <p
            class="font-subheading text-[16px] flex justify-between items-center font-semibold"
            aria-live="polite"
        >
            <span class="text-xs font-bold">TOTAL COST:</span>
            £{{ item.sub_total }}
        </p>
    </div>

    <PopupModel
        :visible="confirmationModalVisible"
        :title="$t('reviewBooking.confirmTitle')"
        @close="confirmationModalVisible = false"
        id="remove-confirmation"
    >
        {{ $t("reviewBooking.confirmMessage") }}
        <template #buttons>
            <DynamicButton
                variant="solid"
                @click="confirmRemoval(true)"
                additionalClasses="mt-4"
                aria-label="Confirm removal of guest"
            >
                {{ $t("common.remove") }}
            </DynamicButton>
            <DynamicButton
                variant="outline"
                @click="confirmRemoval(false)"
                aria-label="Close guest selection modal"
            >
                {{ $t("common.cancel") }}
            </DynamicButton>
        </template>
    </PopupModel>

    <LoadingLogo
        v-if="isLoading"
        :loading="isLoading"
        :message="$t('common.updatingBooking')"
    />
</template>

<script setup lang="ts">
import DynamicButton from "@/components/forms/DynamicButton.vue";
import LoadingLogo from "@/components/global/LoadingLogo.vue";
import PopupModel from "@/components/global/PopupModel.vue";
import { useBookingStore } from "@/stores/booking/booking";
import { useBookingStepsStore } from "@/stores/booking/bookingSteps";
import { usePmsStore } from "@/stores/pms/pms";
import { useSpaStore } from "@/stores/spa/spa";
import type {
    BookingPmsItem,
    BookingSpaItem,
    BookingSpecialServiceItem,
} from "@/types";
import { computed, ref } from "vue";

interface Props {
    title: string;
    item: BookingPmsItem;
    dayBooking: boolean;
}

const props = defineProps<Props>();

const pmsStore = usePmsStore();
const bookingStore = useBookingStore();
const bookingStepsStore = useBookingStepsStore();
const spaStore = useSpaStore();
const confirmationModalVisible = ref(false);
const showDetails = ref(false);
const resolveConfirmation = ref<((isConfirmed: boolean) => void) | null>(null);
const isLoading = ref(false);

const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
    }
    return text;
};

const confirmRemoval = (isConfirmed: boolean) => {
    confirmationModalVisible.value = false;
    if (resolveConfirmation.value) {
        resolveConfirmation.value(isConfirmed);
    }
};

const showConfirmationModal = () => {
    confirmationModalVisible.value = true;

    return new Promise((resolve) => {
        resolveConfirmation.value = resolve;
    });
};

const removePmsItem = async (): Promise<void> => {
    try {
        if (!props.item.id || !props.item.pms_folio_id) {
            throw new Error("PMS item ID or PMS folio ID is missing");
        }

        const isConfirmed = await showConfirmationModal();
        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await pmsStore.removePmsBooking(
            null,
            props.item.id,
            props.item.pms_folio_id
        );

        isLoading.value = false;

        await bookingStepsStore.resetToStep(1);
    } catch (error) {
        console.error("Error during pms item removal", error);
        throw error;
    }
};

const removeTreatment = async (
    pmsItemGuestId: number | null,
    treatment: BookingSpaItem,
    isInclusive: boolean
) => {
    try {
        if (!props.item.id) {
            throw new Error("PMS item ID is missing");
        }

        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await spaStore.removeTreatment(
            treatment,
            props.item.id,
            pmsItemGuestId,
            treatment.treatment_id,
            treatment.spa_folio_id,
            treatment.spa_folio_item_id
        );

        isLoading.value = false;

        if (isInclusive) {
            await bookingStepsStore.resetToStep(2);
        }
    } catch (error) {
        console.error("Error during treatment removal", error);
        throw error;
    }
};

const removeSpecialService = async (
    specialService: BookingSpecialServiceItem
) => {
    try {
        if (!props.item.id) {
            throw new Error("PMS item ID is missing");
        }

        const isConfirmed = await showConfirmationModal();

        if (!isConfirmed) {
            return;
        }

        isLoading.value = true;

        await pmsStore.removePmsSpecialService(
            specialService,
            props.item.id,
            props.item.pms_folio_id,
            specialService.pms_folio_special_service_id,
            specialService.special_service.id
        );

        isLoading.value = false;
    } catch (error) {
        console.error("Error during special service removal", error);
        throw error;
    }
};

const removeItemText = computed(() => {
    if (bookingStore.state.booking.experience === "afternoon_tea") {
        return "common.removeAfternoonTea";
    } else if (bookingStore.state.booking.experience === "spa_day") {
        return "common.removeSpaDay";
    } else if (bookingStore.state.booking.experience === "spa_evening") {
        return "common.removeSpaEvening";
    } else if (bookingStore.state.booking.experience === "stay") {
        return "common.removeRoom";
    } else {
        return "common.remove";
    }
});
</script>
