<template>
    <transition name="fade" appear>
        <div
            v-show="visible"
            class="overlay md:hidden"
            aria-hidden="true"
            @click="closeCalendar"
            @keydown.esc="closeCalendar"
        ></div>
    </transition>
    <transition name="fade-slide" appear>
        <div
            v-show="visible"
            class="base-calendar fixed left-0 right-0 bottom-0 w-full h-min px-6 py-4 rounded bg-ivory-800 z-[61] | md:absolute md:h-full md:flex md:justify-start md:items-center"
            ref="calendarRef"
        >
            <div
                class="touch-control w-24 h-1 bg-black-900/10 rounded-md mx-auto mt-6 mb-10 md:hidden"
                @click="closeCalendar"
                ref="touchControlRef"
            ></div>
            <div class="calendar-container max-w-[700px] w-full mx-auto">
                <VueDatePicker
                    v-model="formattedDate"
                    locale="en"
                    inline
                    auto-apply
                    month-name-format="long"
                    hide-offset-dates
                    :range="isRangeMode"
                    :multi-calendars="2"
                    :month-change-on-scroll="false"
                    :min-date="new Date()"
                    prevent-min-max-navigation
                    :day-names="[
                        $t('rateCalendar.monday'),
                        $t('rateCalendar.tuesday'),
                        $t('rateCalendar.wednesday'),
                        $t('rateCalendar.thursday'),
                        $t('rateCalendar.friday'),
                        $t('rateCalendar.saturday'),
                        $t('rateCalendar.sunday'),
                    ]"
                    :enable-time-picker="false"
                    @update:model-value="updateDateRange"
                    @invalid-select="handleInvalidSelect"
                    @update-month-year="handleMonthYear"
                >
                    <template #day="{ day }">
                        <div class="flex flex-col text-xs font-bold">
                            {{ day }}
                        </div>
                    </template>
                </VueDatePicker>
                <div
                    class="mt-6 flex gap-8 max-w-sm mx-auto w-full items-center justify-center"
                >
                    <DynamicButton
                        variant="solid"
                        @click="confirmDate"
                        additionalClasses="select-dates"
                        aria-label="Confirm selected dates"
                    >
                        <span v-if="isRangeMode">
                            {{ $t("rateCalendar.confirmDatesText") }}
                        </span>
                        <span v-else>
                            {{ $t("rateCalendar.confirmDateText") }}
                        </span>
                    </DynamicButton>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useSwipeDownClose } from "@/composables/useSwipeDownClose";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import DynamicButton from "@/components/forms/DynamicButton.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import moment from "moment";

interface Props {
    startDate: string | null;
    endDate: string | null;
    promo: string | null;
    mode: "range" | "day";
}

interface DateRange {
    start: moment.Moment | null;
    end: moment.Moment | null;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue", "close", "date-selected"]);

const startY = ref(0);
const visible = ref(true);
const dateRange = ref<DateRange>({ start: null, end: null });
const isRangeMode = computed(() => props.mode === "range");
const calendarRef = ref<HTMLElement | null>(null);
const touchControlRef = ref(null);

const updateDateRange = (value: string | [string, string]) => {
    const setDate = (date: string | null) =>
        date && moment(date).isValid() ? moment(date) : null;

    if (Array.isArray(value)) {
        dateRange.value.start = setDate(value[0]);
        dateRange.value.end = setDate(value[1]);
    } else {
        dateRange.value.start = setDate(value);
        dateRange.value.end = isRangeMode.value
            ? setDate(value)
                ? moment(dateRange.value.start).add(1, "day")
                : null
            : null;
    }
};

const formattedDate = computed(() => {
    return dateRange.value.start
        ? dateRange.value.start.format("YYYY-MM-DD")
        : null;
});

const handleInvalidSelect = (value: any) => {
    push.error({
        title: "Sorry!",
        message: "Your selection is invalid. Please try again.",
    });
};

const confirmDate = () => {
    if (isRangeMode.value) {
        if (dateRange.value.start && dateRange.value.end) {
            emit("date-selected", {
                start: dateRange.value.start,
                end: dateRange.value.end,
            });
            closeCalendar();
        }
    } else {
        if (dateRange.value.start) {
            emit("date-selected", {
                start: dateRange.value.start,
                end: dateRange.value.start || dateRange.value.end,
            });
            closeCalendar();
        }
    }
};

const closeCalendar = () => {
    visible.value = false;

    setTimeout(() => {
        emit("close");
    }, 500);
};

const handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
        closeCalendar();
    }
};

const onTouchStart = (event: any) => {
    startY.value = event.touches[0].clientY;
};
const onTouchEnd = (event: any) => {
    const endY = event.changedTouches[0].clientY;
    if (endY - startY.value > 5) {
        closeCalendar();
    }
};

const handleMonthYear = (newMonth: Date) => {
    const startDate = moment(newMonth).startOf("month").format("YYYY-MM-DD");
    const endDate = moment(newMonth).endOf("month").format("YYYY-MM-DD");
};

onMounted(() => {
    window.addEventListener("keydown", handleEscape);

    if (props.startDate) {
        dateRange.value.start = moment(props.startDate);
    }

    if (props.endDate) {
        dateRange.value.end = moment(props.endDate);
    }

    if (props.mode === "day" && dateRange.value.start) {
        dateRange.value.end = moment(dateRange.value.start).add(1, "day");
    }

    const calendarElement = document.querySelector(".rate-calendar");
    if (calendarElement) {
        calendarElement.addEventListener("touchstart", onTouchStart);
        calendarElement.addEventListener("touchend", onTouchEnd);
    }
});

onUnmounted(() => {
    window.removeEventListener("keydown", handleEscape);

    const calendarElement = document.querySelector(".rate-calendar");
    if (calendarElement) {
        calendarElement.removeEventListener("touchstart", onTouchStart);
        calendarElement.removeEventListener("touchend", onTouchEnd);
    }
});

watch(
    () => props.startDate,
    (newValue) => {
        dateRange.value.start = newValue ? moment(newValue) : null;
        if (props.mode === "day" && dateRange.value.start) {
            dateRange.value.end = moment(dateRange.value.start).add(1, "day");
        }
    }
);

watch(
    () => props.endDate,
    (newValue) => {
        dateRange.value.end = newValue ? moment(newValue) : null;
        if (props.mode === "day" && dateRange.value.start) {
            dateRange.value.end = moment(dateRange.value.start).add(1, "day");
        }
    }
);

useSwipeDownClose(calendarRef, closeCalendar);
</script>

<style scoped lang="scss">
:deep(.select-dates) {
    @apply flex-1 bg-gold-900 hover:bg-gold-800;
}
:deep(.dp__main) {
    * {
        @apply shadow-none outline-none border-none bg-transparent rounded-none;
    }

    > div {
        @apply w-full;

        &:first-of-type {
            @apply hidden;
        }
    }

    .dp__menu_inner {
        @apply p-0 gap-4;
    }

    .dp--header-wrap {
        @apply mb-2;
    }

    .dp__month_year_select {
        @apply text-black-900 font-heading text-sm h-auto;
    }

    .dp__calendar_header_item {
        @apply text-secondary font-subheading font-bold uppercase text-center text-xs py-0 p-0 h-auto;
    }

    .dp__month_year_wrap {
        @apply flex items-center justify-center gap-2;
    }

    .dp__month_year_select,
    .dp__month_month_select {
        @apply w-auto pointer-events-none;
    }

    .dp__calendar_item {
        @apply flex items-start font-subheading font-bold uppercase h-12;
    }

    .dp__calendar_row {
        @apply mt-0 mb-0;
    }

    .dp__cell_inner {
        @apply flex-1 flex items-center h-full text-center p-0;

        &.dp__cell_disabled {
            span {
                @apply opacity-0;
            }
        }
    }

    .dp__range_start {
        @apply bg-primary text-ivory-200 rounded-s;

        span {
            @apply transition-all text-ivory-200;
        }
    }

    .dp__range_end {
        @apply border border-solid border-primary text-primary rounded-e;

        span {
            @apply transition-all opacity-0;
        }
    }
    .dp__date_hover {
        @apply rounded-e;
    }

    .dp__date_hover_start,
    .dp__date_hover {
        @apply bg-transparent;
    }

    .dp__range_between {
        @apply bg-highlight text-ivory-300;

        span {
            @apply text-ivory-200;
        }
    }

    .dp__date_hover_end {
        @apply border border-solid border-primary text-primary bg-transparent;
    }

    .dp__active_date {
        @apply bg-primary text-ivory-300;

        span {
            @apply text-ivory-300;
        }
    }

    .dp--tp-wrap {
        @apply w-full max-w-full my-4;
    }

    .dp__tp_inline_btn_top,
    .dp__tp_inline_btn_bottom {
        @apply hidden;
    }

    .dp__time_display {
        @apply text-primary font-subheading font-semibold text-lg p-0;
    }

    .dp__time_col_reg_inline {
        @apply p-1;
    }

    .dp__overlay_cell {
        @apply text-primary font-subheading font-semibold p-1;
    }

    .dp__overlay_cell_disabled {
        @apply opacity-40;
    }

    .dp__time_picker_overlay_container,
    .dp__overlay_container,
    .dp__overlay {
        @apply bg-ivory-900 opacity-95 justify-center;

        .dp__button_bottom {
            @apply hidden;
        }
    }

    .dp__overlay_container {
        @apply flex;
    }

    .dp__overlay_col {
        @apply p-0;
    }
}
</style>
