import { reactive } from "vue";
import { defineStore } from "pinia";
import type {
    PreArrivalBooking,
    PreArrivalBookingGuest,
    Error,
    MedicalForm,
} from "@/types/index";
import api from "@/api/axios";
import { useUserStore } from "@/stores/auth/user";
import router from "@/router";

interface PreArrivalState {
    isLoading: boolean;
    bookingRef: string;
    guestBookingRef: string;
    guestNumber: number | null;
    booking: PreArrivalBooking | null;
    guestBooking: PreArrivalBookingGuest | null;
    error: Error;
}

const initialState = (): PreArrivalState => ({
    isLoading: false,
    bookingRef: "",
    guestBookingRef: "",
    guestNumber: null,
    booking: null,
    guestBooking: null,
    error: {
        hasError: false,
        message: "",
    },
});

export const usePreArrivalStore = defineStore(
    "preArrival",
    () => {
        const state = reactive<PreArrivalState>(initialState());
        const userStore = useUserStore();

        async function getPreArrivalBooking(): Promise<boolean> {
            state.isLoading = true;

            try {
                const response = await api.get("api/pre-arrival/booking/" + state.bookingRef);

                state.booking = response.data.data;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                push.error({
                    title: "Sorry!",
                    message: state.error.message,
                });
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function getPreArrivalGuestBooking(): Promise<boolean> {
            state.isLoading = true;

            if (!userStore.state.user) {
                console.error("User not found");

                return false;
            }

            try {
                const response = await api.post("api/pre-arrival/booking/guest", {
                    booking_ref: state.guestBookingRef,
                    user_id: userStore.state.user?.id,
                });

                state.guestBooking = response.data.data;

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                errorResponse.response?.data?.message || "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        async function submitPreArrivalForm(
            medicalForm: MedicalForm
        ): Promise<boolean> {
            state.isLoading = true;

            if (!userStore.state.user) {
                console.error("User not found");

                return false;
            }

            try {
                const response = await api.post("api/pre-arrival/consultation/add/" + state.guestBookingRef + "/" + state.guestNumber, medicalForm);

                if(response.data.success) {
                    push.success({
                        title: "Success!",
                        message: "Your pre-arrival consultation has been submitted successfully. We look forward to seeing you soon!",
                    });
                }

                router.push({ name: "Index" });

                return true;
            } catch (errorResponse: any) {
                state.error.hasError = true;
                state.error.message =
                    errorResponse.response?.data?.message ||
                    "An error occurred.";
                return false;
            } finally {
                state.isLoading = false;
            }
        }

        const $reset = () => {
            Object.assign(state, initialState());
        };

        return {
            state,
            getPreArrivalBooking,
            getPreArrivalGuestBooking,
            submitPreArrivalForm,
            $reset,
        };
    }
);
